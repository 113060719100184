// !!IMPORTANT!! Creator dynamic content start

:root {
  --primary-alpha: #E8152799;
  --primary-lightest: #e98d95;
  --primary-lighter: #f07983;
  --primary-light: #ec5360;
  --primary: #E81527;
  --primary-dark: #bc1321;
  --primary-darker: #97101b;
  --primary-darkest: #6d0e16;
  --primary-disabled: #FF5B6B;
  --secondary-alpha: #f7ae28bb;
  --secondary-lightest: #fff1d6;
  --secondary-lighter: #ffe1a9;
  --secondary-light: #fdce77;
  --secondary: #f7ae28;
  --secondary-dark: #e3a127;
  --secondary-darker: #c18923;
  --secondary-darkest: #9d701e;
  --secondary-disabled: #fff;
  --state-success: #8cf5c6;
  --state-waiting: #5F6A71;
  --state-info: #7289DA;
  --state-warn: #FAA618;
  --state-danger: #F14746;
  --base-text: #222;
  --base-link: #0D8BF2;
  --base-bg: #fbfbfb;
  --base-bgFallback: #000b;
  --base-alpha: #0008;
  --base-lightest: #fff;
  --base-lighter: #f9f9f9;
  --base-light: #f0f0f0;
  --base: #d0d0d0;
  --base-dark: #999;
  --base-darker: #777;
  --base-darkest: #000;
  --neutral-light-gray: #DEDEDE;
  --neutral-dark-gray: #2D2D2D;
  --neutral-off-white: #F4F4F4;
  --neutral-mid-gray: #777777;

}

[data-theme="dark"] {
  --state-success: #15955c;
  --state-info: #496ae2;
  --state-warn: #da8a03;
  --base-text: #f0f0f0;
  --base-bg: #333;
  --base-bgFallback: #fffb;
}

@media (prefers-color-scheme: dark) {
  [data-theme="os"] {
  --state-success: #15955c;
  --state-info: #496ae2;
  --state-warn: #da8a03;
  --base-text: #f0f0f0;
  --base-bg: #333;
  --base-bgFallback: #fffb;
}
}
// !!IMPORTANT!! End of Creator dynamic content
