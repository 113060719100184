
/******************************************
 * Components global scope rules
 ******************************************/
 body {
  overflow: hidden;
  
  &[data-menu-open] {
    overflow: hidden;
  }

  &[data-modal-open] {
    overflow: hidden;
  }

  &[data-loading] {
    overflow: hidden;
  }
}

/*
 * CONTENT
 */
[data-content-wrapper],
.content-wrapper {
  position: relative;
  width: 100%;
  padding: 0.5rem;

@include gt-tablet {
  padding: 0;
  max-width: 144rem;
  margin: auto;
} 
}

[data-content-wrapper="small"] {
  max-width: 71rem;
}

.opblock-section-header {
  background-color: #2225 !important;
  color: #fff !important;
}

.swagger-ui .model-box-control:focus,
.swagger-ui .models-control:focus,
.swagger-ui .opblock-summary-control:focus {
  outline: solid 2px #999 !important;
}

#root, .root {
  min-height: 100%;
}

.window-drag-handler,
.draggable {
  outline: none;
}

.module-window {
  height: 100%;
}

[role="listbox"].ui.item.dropdown {
  min-width: max-content;
}

form {
  padding: .5rem;

  .field {
    display: flex;
    flex-wrap: wrap;
    gap: .2rem;

    label {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: .2rem;
      padding: .2rem;
    }

    input {
      flex-grow: 1;
    }
  }

  &[data-cols="2"] {
    display: grid;
    grid-template-columns: minmax(fit-content, 10rem) 1fr;
  }

  // input:NOT([type]),
  // input[type="text"],
  // input[type="phone"],
  // input[type="email"],
  // input[type="password"],
  // select {
  //   padding: 1rem .5rem;
  //   flex-grow: 1;
  //   outline-color: $c-base;
  //   // border-color: $c-base;
  //   color: $c-base-text;

  //   &[read-only] {
  //     outline-color: transparent;
  //     border-color: transparent;
  //     background-color: transparent;
  //   }
  // }

  // label {
  //   display: flex;
  //   flex-wrap: wrap;
  //   gap: 1rem;
  //   align-items: center;
  //   margin: .5rem;

  //   &[data-size="s"] {
  //     display: grid;
  //     grid-template-columns: 25% auto;
  //   }
  // }
}

// !!IMPORTANT!! Creator dynamic content start
// Defining the REM size
html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
  color: $c-base-text;
}

body {
  font-size: 1.6rem;
  color: $c-base-text;
}

a {
  color: $c-base-text;

  &:visited {
    color: $c-secondary;
  }
}
// Defining typographies
h1 {
  font-size: 2.2rem;
  font-weight: bolder;

  @include gt-phone { // smaller in mobile devices
    font-size: 2.4rem;
  }
}
h2 {
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
}
h3 {
  font-size: 1.4rem;
  font-weight: bold;
  text-decoration: none;
}
h4 {
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
}
h5 {
  font-size: 1.1rem;
  font-weight: bold;
  text-decoration: none;
}
h6 {
  font-size: .87rem;
  font-weight: bold;
  text-decoration: none;
}
// !!IMPORTANT!! End of Creator dynamic content
