@import 'fonts';

// variables only
@import 'utils/styles/_colors';
@import 'utils/styles/_sizes';
@import 'utils/styles/_shapes';
@import 'utils/styles/_mixins';
// global selectors
@import 'utils/styles/typography';
@import 'utils/styles/css-vars-color';
@import 'utils/styles/_globals';

*, *::before, *::after {
  box-sizing: border-box;
}

html {
  overflow: hidden;
}

html, body {
  min-height: 100vh;
  
  margin: 0;
  padding: 0;
  background-color: #fff;
  text-decoration-skip-ink: auto;
  
  font-smoothing: antialiased;
  webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-display: swap;

  overflow-x: hidden;
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

#root {
  height: 100%;
}

svg {
  &[data-icon="1"] {
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-exit {
  display: none;
}

a {
  color: #0D8BF2;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &[disabled] {
    opacity: .5;
    filter: grayscale(1);
    text-decoration: line-through;
  }
}

p {
  line-height: 3rem;
}

mark {
  background-color: transparent;
  outline: solid 1px;
  color: currentColor;
  padding: 0 0.2rem;
  margin: 0 0.2rem;
}

@media screen and
  (prefers-reduced-motion: reduce), 
  (update: slow) {
  * {
    animation-duration: 0.001ms !important;
    animation-delay: 0s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

body[data-reduced-motion="1"] {
  * {
    animation-duration: 0.001ms !important;
    animation-delay: 0s !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.001ms !important;
  }
}

body[data-reduced-blur="1"] {
  --reduced-blur: 0;
  --reduced-blur-bg: var(--base-bgFallback);
}

.ui.labeled.input>.label:not(.corner)[tabindex] {
  &:hover {
    background-color: $c-base;
  }
}

.rgt-wrapper {
  color: #333;
  font-size: 1.2rem;

  .rgt-row-selected {
    background-color: $c-base;
  }

  .rgt-footer-clear-selection-button {
    display: none;
  }

  .rgt-cell-header {
    font-weight: bold;
  }
}

.ui.form {
  min-height: 100%;
  flex: 1 1;

  .field>label {
    font-size: 1.2rem;
  }
}

.rgt-container {
  background-color: transparent !important;
}

.rgt-column-sort-ghost {
  z-index: 999999999 !important;
}

.rdw-editor-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  // padding: 0;
  margin: 0;
  background-color: $c-base-lighter;
  color: $c-base-darkest;

  .rdw-editor-main {
    padding: 0 1rem;
    margin: -5px 0;
  }
}

body[data-logout="1"] {
  div[data-page-container],
  div[data-main-menu="1"] {
    filter: grayscale(1) brightness(1.5);
  }
}

.hidden-input {
  position: absolute;
  pointer-events: none;
  width: 1px !important;
  // height: 0;
  padding: 0;
  overflow: hidden !important;
  margin: 0;
  background: transparent !important;
  border: none !important;
  outline: none !important;
  // visibility: hidden;
}

@media only screen and (min-width: 992px) {
  .ui.selection.dropdown .menu {
    max-height: 10rem;
  }
}

:root {
  // TODO: use a variable from styleguide instead, to add support for dark mode
  --rgt-background-color: #ffffffb3;
}

.tox-statusbar__branding,
.tox-promotion {
  display: none !important;
}

// .ui.form .field:last-child, .ui.form .fields:last-child .field {
//   margin-bottom: 1rem;
// }

.field {
  input {
    font-size: 1.4rem !important;
    padding: 0.5rem 0.7rem !important;
  }
}
