
// !!IMPORTANT!! Font family definitions
@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Black.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-BlackItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Bold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-BoldItalic.ttf) format("truetype");
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Italic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-LightItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Medium.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-MediumItalic.ttf) format("truetype");
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Roboto;
  src: local(Roboto),
       url(/fonts/Roboto/Roboto-ThinItalic.ttf) format("truetype");
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
  font-display: swap;
}
// !!IMPORTANT!! End of Font family

// !!IMPORTANT!! Default body font family definition
html, body {
  font-family: Roboto, sans-serif, "Brush Script MT";
}
// !!IMPORTANT!! End of Default body font family definition
