@import 'utils/styles/_colors';
@import 'utils/styles/_mixins';

.molecule__cookies-banner-container {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    background-color: #fff;
    padding: 1rem;
    width: 50%;
    min-width: 30rem;
  }
  

  /******************************************
   *      RESPONSIVITY (media queries)      *
   ******************************************/
  @include gt-phone {
    // TODO: add specifications for devices that are greater than phones
    // example: .content { ... }
  }
}
