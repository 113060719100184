
// !!IMPORTANT!! Creator dynamic content start
// primary colors
$c-primary-alpha                 : var(--primary-alpha);
$c-primary-lightest              : var(--primary-lightest);
$c-primary-lighter               : var(--primary-lighter);
$c-primary-light                 : var(--primary-light);
$c-primary                       : var(--primary);
$c-primary-dark                  : var(--primary-dark);
$c-primary-darker                : var(--primary-darker);
$c-primary-darkest               : var(--primary-darkest);
$c-primary-disabled              : var(--primary-disabled);
// secondary colors
$c-secondary-alpha               : var(--secondary-alpha);
$c-secondary-lightest            : var(--secondary-lightest);
$c-secondary-lighter             : var(--secondary-lighter);
$c-secondary-light               : var(--secondary-light);
$c-secondary                     : var(--secondary);
$c-secondary-dark                : var(--secondary-dark);
$c-secondary-darker              : var(--secondary-darker);
$c-secondary-darkest             : var(--secondary-darkest);
$c-secondary-disabled            : var(--secondary-disabled);
// state colors
$c-state-success                 : var(--state-success);
$c-state-waiting                 : var(--state-waiting);
$c-state-info                    : var(--state-info);
$c-state-warn                    : var(--state-warn);
$c-state-danger                  : var(--state-danger);
// base colors
$c-base-text                     : var(--base-text);
$c-base-link                     : var(--base-link);
$c-base-bg                       : var(--base-bg);
$c-base-bgFallback               : var(--base-bgFallback);
$c-base-alpha                    : var(--base-alpha);
$c-base-lightest                 : var(--base-lightest);
$c-base-lighter                  : var(--base-lighter);
$c-base-light                    : var(--base-light);
$c-base                          : var(--base);
$c-base-dark                     : var(--base-dark);
$c-base-darker                   : var(--base-darker);
$c-base-darkest                  : var(--base-darkest);
$c-neutral-light-gray            : var(--neutral-light-gray);
$c-neutral-off-white             : var(--neutral-off-white);
$c-neutral-dark-gray             : var(--neutral-dark-gray);
$c-neutral-mid-gray              : var( --neutral-mid-gray);

/*
 * Combinations of colors
 */

// headbar combination
@mixin c-headbar {
  color           : $c-base-text;
  background-color: $c-primary;
}
  
// highlight combination
@mixin c-highlight {
  color           : $c-base-text;
  background-color: $c-secondary;
}
  
// success combination
@mixin c-success {
  color           : $c-base-text;
  background-color: $c-state-success;
}
  
// danger combination
@mixin c-danger {
  color           : $c-base-lightest;
  background-color: $c-state-danger;
}
  
// warn combination
@mixin c-warn {
  color           : $c-base-text;
  background-color: $c-state-warn;
}
  
// info combination
@mixin c-info {
  color           : $c-base-text;
  background-color: $c-state-info;
}
  // !!IMPORTANT!! End of Creator dynamic content
