@import 'utils/styles/_colors';
@import 'utils/styles/_sizes';
@import 'utils/styles/_mixins';

// !!IMPORTANT!! Creator dynamic content start
// Defining variant typographies
[data-typography="normal"] {
  @include typography-normal;
}

[data-typography="title"] {
  @include typography-title;
}

[data-typography="message"] {
  @include typography-message;
}

[data-typography="small"] {
  @include typography-small;
}

[data-typography="link"] {
  @include typography-link;
}
// !!IMPORTANT!! End of Creator dynamic content
